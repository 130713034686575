import { Loginbutton, Mobiletop } from '../../components';

export default function Login() {
  return (
    <>
      <Mobiletop pagename="로그인" />
      <Loginbutton />
    </>
  );
}
